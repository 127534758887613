import { api } from "api/api";
import {
  checkTokenEndPoint,
  loginEndPoint,
  myProfileEndPoint,
  regenerateTokenEndPoint,
} from "api/config";

export const loginService = {
  getLoginDetails,
  getmyProfile,
  checkIsTokenValid,
  regenerateToken,
};

function checkIsTokenValid(params: string) {
  return api.get(checkTokenEndPoint + params);
}
function regenerateToken(params: string) {
  return api.get(regenerateTokenEndPoint + params);
}

function getLoginDetails(params: string) {
  return api.post(loginEndPoint + params);
}

function getmyProfile(params: string) {
  return api.get(myProfileEndPoint + params);
}
