import { fetchProfileDataQuery } from "./profileQueries";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USERS_LIST } from "constants/users";
import { MatchData } from "interfaces/profile";
import { UserDataApi } from "interfaces/users";
import type { AppState } from "redux/store";

export const updateProfiles = createAsyncThunk(
  "updateProfile",
  // if you type your function argument here
  async ({ page, credentials, type }: { page: number; credentials: string; type: USERS_LIST }) =>
    await fetchProfileDataQuery({ page, credentials, type }).then((data: any) => data),
);

export interface ProfileSlice {
  profileDatas: UserDataApi[];
  selectedId: string;
  selectedIndex: number;
  selectedType: USERS_LIST;
  searchTerms?: any;
  loading?: string;
}
export interface MatchSlice {
  match?: MatchData;
}

export interface ProfileImageLockSlice {
  profileImageLock?: boolean;
}

const initialState = {
  profileDatas: [{}],
  match: {},
  selectedId: "",
  selectedIndex: 0,
  loading: "idle",
  selectedType: USERS_LIST.MYMATCHES,
  profileImageLock: true,
  searchTerms: {},
};

// #. Common slice contains the common state methods
export const selectedProfilesSlice = createSlice({
  name: "selectedProfiles",
  initialState,
  reducers: {
    setSelectedProfile: (state, action: PayloadAction<ProfileSlice>) => {
      state.profileDatas = action.payload.profileDatas;
      state.selectedId = action.payload.selectedId;
      state.selectedIndex = action.payload.selectedIndex;
      state.selectedType = action.payload.selectedType;
      state.searchTerms = action.payload.searchTerms;
    },
    setMatch: (state, action: PayloadAction<MatchSlice>) => {
      state.match = action.payload.match || {};
    },
    setProfileImageLockStatus: (state, action: PayloadAction<ProfileImageLockSlice>) => {
      state.profileImageLock = action.payload.profileImageLock ?? true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfiles.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateProfiles.fulfilled, (state, { payload }) => {
      state.loading = "succeeded";
      state.profileDatas = [...state.profileDatas, ...payload.content];
    });
    builder.addCase(updateProfiles.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export const { setSelectedProfile, setMatch, setProfileImageLockStatus } =
  selectedProfilesSlice.actions;

// #. State for loader component
export const selectedProfileData = (state: AppState) => state.selectedProfiles;

// #. Export the reducers
export default selectedProfilesSlice.reducer;
