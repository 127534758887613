import React from "react";
import styled from "./cssLoader.module.scss"

const CssLoader = () => {
	return (
		<div className={styled["lds-grid"]}>
			<div className={styled["lds-grid__container"]}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default CssLoader;
