import axios from "axios";
import { baseUrl } from "./config";

const token = localStorage.getItem("token");

const axiosApiInstance = axios.create({
  withCredentials: true,
  // baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const api = {
  get,
  getById,
  post,
  put,
  delete: _delete,
  patch,
};

function get(apiEndpoint, authorizationHeader = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .get(baseUrl + apiEndpoint)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function getById(apiEndpoint, authorizationHeader = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .get(baseUrl + apiEndpoint)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function post(apiEndpoint, payload, authorizationHeader = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .post(baseUrl + apiEndpoint, payload)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function put(apiEndpoint, payload, authorizationHeader = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .put(baseUrl + apiEndpoint, payload)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function _delete(apiEndpoint, authorizationHeader = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .delete(baseUrl + apiEndpoint)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function patch(apiEndpoint, payload, authorizationHeader = null, multipart = null) {
  axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axiosApiInstance
    .patch(baseUrl + apiEndpoint, payload)
    .then((response) => {
      return responseHandler(response);
    })
    .catch((err) => {
      return errorHandler(err);
    });
}

function responseHandler(response) {
  return response.data;
}

function errorHandler(error) {
  // if (
  // 	error.response &&
  // 	error.response.status !== 400 &&
  // 	error.response.status !== 401 &&
  // 	error.response.status !== 403
  // ) {
  // 	if (sessionStorage.getItem("_user") === "login") {
  // 		window.location = "/home/error";
  // 	} else {
  // 		window.location = "/contribute/error";
  // 	}
  // }
  // console.log(error)
  return Promise.reject(error.response && error.response.data);
}
