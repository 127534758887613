import { createSlice } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

const initialState = "";

// #. Common slice contains the common state methods
export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setRegistration: (state, { payload }) => {
      state = payload;
    },
  },
});

export const { setRegistration } = registrationSlice.actions;

// #. State for loader component
export const registrationData = (state: AppState) => state.registration;

// #. Export the reducers
export default registrationSlice.reducer;
