import { api } from "api/api";
import {
	careerMatchEndPoint,
	educationMatchEndPoint,
	featuredFromDistrictsEndPoint,
	locationMatchEndPoint,
	onlineUsersEndPoint,
	postCancelRequestAPI,
	postExpIntReply,
	pstExpressInterest,
	pstShortlist,
	recentVisitorsEndPoint,
	yourMatchesEndPoint,
} from "api/config";

export const homeListingService = {
	getYourMatches,
	getOnlineUsers,
	getRecentVisitors,
	getFeaturedFromDistricts,
	getEducationMatch,
	getCareerMatch,
	getLocationMatch,
	postShortlist,
	postExpressInterest,
	postExpressInterestReply,
	postCancelRequest
};

function getYourMatches(params: string) {
	return api.post(yourMatchesEndPoint + params);
}

function getOnlineUsers(params: string) {
	return api.post(onlineUsersEndPoint + params);
}

function getRecentVisitors(params: string) {
	return api.post(recentVisitorsEndPoint + params);
}

function getFeaturedFromDistricts(params: string) {
	return api.post(featuredFromDistrictsEndPoint + params);
}

function getEducationMatch(params: string) {
	return api.post(educationMatchEndPoint + params);
}

function getCareerMatch(params: string) {
	return api.post(careerMatchEndPoint + params);
}

function getLocationMatch(params: string) {
	return api.post(locationMatchEndPoint + params);
}

function postShortlist(params: string) {
	return api.post(pstShortlist + params);
}

function postExpressInterest(params: string) {
	return api.post(pstExpressInterest + params);
}

function postExpressInterestReply(params: string) {
	return api.post(postExpIntReply + params);
}

function postCancelRequest(params: string) {
	return api.post(postCancelRequestAPI + params);
}
