import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserProfile } from "interfaces/users";
import type { RootState } from "redux/store";
import { loginService } from "services/loginService";

export const loginPost = createAsyncThunk(
  "loginData",
  // if you type your function argument here
  async (params: string) => await loginService.getLoginDetails(params),
);

export const profileData = createAsyncThunk(
  "profile",
  // if you type your function argument here
  async (params: string) => await loginService.getmyProfile(`type=self&${params}`),
);

interface UserState {
  permission: boolean;
  pass: string;
  entities: [];
  profile: UserProfile;
  token: string;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState = {
  permission: false,
  pass: "",
  entities: [],
  profile: "",
  loading: "idle",
  token: "",
} as UserState;

export const loginSlice = createSlice({
  name: "login",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    permissionStatus: (state, { payload }) => {
      state.permission = payload;
    },
    setPass: (state, { payload }) => {
      state.pass = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginPost.pending, (state) => {
      state.loading = "pending";
      state.entities = [];
      state.permission = false;
    });
    builder.addCase(loginPost.fulfilled, (state, { payload }) => {
      state.loading = "succeeded";
      state.entities = payload;
    });
    builder.addCase(loginPost.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(profileData.fulfilled, (state, { payload }) => {
      state.loading = "succeeded";
      state.profile = payload;
    });
  },
});

// export const { fetchUser } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const loginData = (state: RootState) => state.login;
export const permission = (state: RootState) => state.login.permission;
export const pass = (state: RootState) => state.login.pass;
export const profileSelector = (state: RootState) => state.login.profile;
export const token = (state: RootState) => state.login.token;
export const { permissionStatus, setPass, setToken } = loginSlice.actions;
export default loginSlice.reducer;
