import { USERS_LIST } from "constants/users";
import { homeListingService } from "services/homeListingService";

export const fetchProfileDataQuery = async ({ page, credentials, type }: { page: number, credentials: string, type: USERS_LIST }) => {

    const obj = `${credentials}&page=${page}`;

    let mainService: any;
    if (type === USERS_LIST.MYMATCHES) {
        mainService = homeListingService.getYourMatches(obj);
    } else if (type === USERS_LIST.USERSONLINE) {
        mainService = homeListingService
            .getOnlineUsers(obj)
    } else if (type === USERS_LIST.RECENTVISITORS) {
        mainService = homeListingService
            .getRecentVisitors(obj)
    } else if (type === USERS_LIST.FEATUREDFROMDIST) {
        mainService = homeListingService
            .getFeaturedFromDistricts(obj)
    } else if (type === USERS_LIST.EDUCATIONMATCH) {
        mainService = homeListingService
            .getEducationMatch(obj)
    } else if (type === USERS_LIST.CAREERMATCH) {
        mainService = homeListingService
            .getCareerMatch(obj)
    } else if (type === USERS_LIST.LOCATIONMATCH) {
        mainService = homeListingService
            .getLocationMatch(obj)
    }
    return await mainService?.then((data: any) => {
        if (data?.errors) {
            return { content: [data] };
        }
        return { content: [...data] };
    });
};