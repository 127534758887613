import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface ISelectedPackage {
  id: number | null;
}

const initialState: ISelectedPackage = {
  id: null,
};

export const packageSlice = createSlice({
  name: "selectedPackage",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserSelectedPackage: (state, { payload }) => {
      state.id = payload;
    },
  },
});

export const selectedPackage = (state: RootState) => state.selectedPackage;

export const { setUserSelectedPackage } = packageSlice.actions;

export default packageSlice.reducer;
