export enum USERS_LIST {
  MYMATCHES = "My Matches",
  USERSONLINE = "Users Online",
  RECENTVISITORS = "Recent Visitors",
  FEATUREDFROMDIST = "Featured From Dist.",
  EDUCATIONMATCH = "Education Match",
  CAREERMATCH = "Career Match",
  LOCATIONMATCH = "Location Match",
  RELATEDPROFILE = "Related Profile",
  SEARCHRESULT = "Search Result",
  SKIPPEDPROFILES = "Skipped Profiles",
}
