import React, { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import CssLoader from "features/common/cssLoader/CssLoader";
import "styles/globals.scss";

const Router = React.lazy(() => import("routes/Router"));

const queryClient = new QueryClient();

const App = () => {
	return (
		<Suspense fallback={<CssLoader />}>
			<QueryClientProvider client={queryClient}>
				<Router />
			</QueryClientProvider>
		</Suspense>
	);
};

export default App;
