import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginReducer from "features/login/loginSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import selectedProfilesReducer from "features/profile/profile.slice";
import selectedPackageReducer from "features/packages/packagesSlice";
import registrationReducer from "features/Registration/registration.slice";

const reducers = combineReducers({
  login: loginReducer,
  selectedProfiles: selectedProfilesReducer,
  selectedPackage: selectedPackageReducer,
  registration: registrationReducer,
});

//Persist Configuration
const persistConfig = {
  key: "root",
  storage,
  blacklist: [], // will not be persisted
  whitelist: ["login", "selectedProfiles", "selectedPackageReducer", "registration"], // will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppStore = ReturnType<any>;
export type AppState = ReturnType<AppStore["getState"]>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
