export const baseUrl = process.env.REACT_APP_NIK_API_BASE_URL;

export const tokenAuthEndPoint = "token-auth.php?"; // user_email=xxx@gmail.com&user_password=xxx
export const checkTokenEndPoint = "check-token.php?"; // user_email=xxx@gmail.com&token=xxx
export const regenerateTokenEndPoint = "regenerate-token.php?"; // user_email=xxx@gmail.com&token=xxx
export const loginEndPoint = "getx_login.php?"; // user_email=xxx@gmail.com&user_password=xxx
export const yourMatchesEndPoint = "getx_suggested_users.php?"; // user_id=85&user_password=xxx&page=0
export const onlineUsersEndPoint = "getx_online_users.php?"; // user_id=85&user_password=xxx&page=1
export const recentVisitorsEndPoint = "getx_recent_visitors.php?"; // user_id=85&user_password=xxx&page=1
export const featuredFromDistrictsEndPoint = "getx_featured_districts.php?"; // user_id=85&user_password=xxx&page=1
export const educationMatchEndPoint = "getx_edu_match.php?"; // user_id=85&user_password=xxx&page=1
export const careerMatchEndPoint = "getx_career_match.php?"; // user_id=85&user_password=xxx&page=1
export const locationMatchEndPoint = "getx_location_match.php?"; // user_id=85&user_password=xxx&page=1
export const getStatisticsEndPoint = "getx_statistics.php?"; // user_id=85&user_password=xxx
export const myProfileEndPoint = "getx_myprofile.php?"; // type=both&user_password=open4me&user_id=85 (type = both/self ; self:- minimum info; both:-all possible info)
export const notificationEndPoint = "get_notifications.php?"; // user_id=85&user_password=123456&page=0
export const pstShortlist = "pst_shortlist.php?"; // user_id=85&user_password=open4me&to_user_id=84&status=true
export const pstExpressInterest = "pst_exp_int.php?"; // user_id=85&user_password=open4me&to_user_id=84
export const getChatListEndPoint = "getx_chat_list.php?"; // user_password=123456&user_id=85&page=0
export const postChatViewEndPoint = "pst_chatbox.php?"; // user_id=85&user_password=123456&to_user_id=208140
export const postExpIntReply = "pst_exp_int_reply.php?"; // user_id=85&user_password=open4me&to_user_id=84&action=accept
export const postCancelRequestAPI = "pst_cancel_request.php?"; // user_id=85&user_password=123456&to_id=203455
export const getRelatedProfilesMatchEndPoint = "getx_related_profiles_match.php?"; // user_id=85&user_password=open4me&to_user_id=253765
export const getProfileEndPoint = "getx_profile.php?"; // user_id=85&user_password=open4me&to_user_id=253765
export const postPhotoPasswordRequestEndPoint = "pst_photo_password_request.php?"; // user_id=85&user_password=open4me&to_user_id=99340&type=send
export const getPictureEndPoint = "getx_pics.php?"; // user_id=85&user_password=open4me&to_user_id=84&photo_pwd=5284
export const getFormElementsEndPoint = "getx_form_elements.php?"; // https://www.nikahinkerala.com/appx/getx_form_elements.php?type=user_marital_status&form_type=search
export const fullSearchFields = "getx_search_fields_v2.php?"; // https://www.nikahinkerala.com/appx/getx_search_fields.php?user_id=85&user_password=open4me
export const postSubmitSearch = "getx_search.php?"; // http://www.nikahinkerala.com/appx/getx_search.php?user_id=85&user_password=open4me
export const getLocation = "getx_locality.php?";
export const getPackagesUrl = "getx_packages_v2.php?"; //https://www.nikahinkerala.com/appx/getx_packages_v2.php?user_id=85&user_password=open4me
export const getPaymentMethodsUrl = "getx_payment_methods_v2.php?"; //https://www.nikahinkerala.com/appx/getx_payment_methods_v2.php?user_id=85&user_password=open4me
export const paymentAPI = "pst_order_v2.php?"; //http://www.nikahinkerala.com/appx/pst_order_v2.php?user_id=85&user_password=open4me&package_id=2&pay_method_id=1
export const paymentStatusAPI = "getx_transaction_status_v2.php?"; //https://www.nikahinkerala.com/appx/getx_transaction_status_v2.php?user_id=85&user_password=open4me&order_id=12545
export const signupFormAPI = "getx_search_fields_v2.php?"; //https://www.nikahinkerala.com/appx/getx_search_fields_v2.php
export const postSkipActionAPI = "pst_skip.php?"; //http://www.nikahinkerala.com/appx/pst_skip.php?user_id=85&user_password=open4me&to_user_id=84&act=skip/unskip
export const getSkippedProfilesAPI = "getx_skipped_profiles.php?"; //https://www.nikahinkerala.com/appx/getx_skipped_profiles.php?user_id=85&user_password=open4me
export const postReportAPI = "pst_report.php?"; //http://www.nikahinkerala.com/appx/pst_report.php?user_id=85&user_password=open4me&to_user_id=99948&alert_type=spam&alert_comment=test
export const getReportTypeAPI = "getx_form_elements.php?"; //https://www.nikahinkerala.com/appx/getx_form_elements.php?type=report_type
export const postBlockAPI = "pst_block.php?"; //http://www.nikahinkerala.com/appx/pst_block.php?user_id=85&user_password=open4me&to_user_id=84&act=block/unblock
export const getBlockedProfilesAPI = "getx_blocked_profiles.php?"; //https://www.nikahinkerala.com/appx/getx_blocked_profiles.php?user_id=85&user_password=open4me
export const getRegistrationStep1API = "getx_registration_step1.php?"; //https://www.nikahinkerala.com/appx/getx_registration_step1.php
export const getRegistrationStep2API = "getx_registration_step2.php?"; //https://www.nikahinkerala.com/appx/getx_registration_step1.php
export const getRegistrationStep3API = "getx_registration_step3.php?"; //https://www.nikahinkerala.com/appx/getx_registration_step1.php
export const getRegistrationStep4API = "getx_registration_step4.php?"; //https://www.nikahinkerala.com/appx/getx_registration_step1.php
export const getRegistrationStep5API = "getx_registration_step5.php?"; //https://www.nikahinkerala.com/appx/getx_registration_step1.php
export const postRegistrationAPI = "pst_registration.php"; //https://www.nikahinkerala.com/appx/pst_registration.php
/*
http://www.nikahinkerala.com/appx/getx_locality.php?typ=state&cname=India
http://www.nikahinkerala.com/appx/getx_locality.php?typ=district&sname=Kerala
http://www.nikahinkerala.com/appx/getx_locality.php?typ=city&dname=Kollam
*/
